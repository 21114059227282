// @ts-nocheck
import {NAME} from './constants';

export const RESET = `${NAME}/RESET`;
const SUBMIT = `${NAME}/SUBMIT/REQUEST`;
const SUBMIT_FAIL = `${NAME}/SUBMIT/FAILURE`;

export const submitActionGroup = {
    SUBMIT,
    SUBMIT_FAIL,
    submit: (form, data, resolve, reject) => ({
        type: SUBMIT,
        meta: {form, resolve, reject},
        payload: data,
    }),
    submitFailure: (form) => ({
        type: SUBMIT_FAIL,
        meta: {form},
    }),
};

export const reset = (form) => ({
    type: RESET,
    meta: {form},
});
