import {combineReducers, Reducer} from 'redux';

import type {UserDto} from '../types';
import {userActionGroup} from './actions';

const loggedUser: Reducer<Readonly<UserDto | null>> = (state = null, action) => {
    switch (action.type) {
        case userActionGroup.SET_LOGGED_USER:
            return action.payload;
        case userActionGroup.REMOVE_LOGGED_USER:
            return null;
        default:
            return state;
    }
};

const isLoggedUserAdmin: Reducer<Readonly<boolean>> = (state = false, action) => {
    switch (action.type) {
        case userActionGroup.SET_IS_LOGGED_USER_ADMIN:
            return action.payload;
        case userActionGroup.REMOVE_LOGGED_USER:
            return false;
        default:
            return state;
    }
};

export default combineReducers({loggedUser, isLoggedUserAdmin});
