import {fetch, fn} from 'core/util';

import type {
    FirebaseLoginRequest,
    FirebaseLoginResponse,
    FirebaseLoginUsingCustomTokenRequest,
    FirebaseChangePasswordRequest,
    FirebaseChangePasswordResponse,
} from './types';
import {getEndpoint} from './util';

const firebaseLogin = ({email, password, firebaseApiKey}: FirebaseLoginRequest) => {
    const body = {
        returnSecureToken: true,
        email,
        password,
    };

    return fetch.doPost<FirebaseLoginResponse, typeof body>(
        'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword',
        body,
        false,
        {
            key: firebaseApiKey,
        },
        fn.identity,
        fn.identity,
        true,
    );
};

const firebaseLoginUsingCustomToken = ({customToken, firebaseApiKey}: FirebaseLoginUsingCustomTokenRequest) => {
    const body = {
        returnSecureToken: true,
        token: customToken,
    };

    return fetch.doPost<FirebaseLoginResponse, typeof body>(
        'https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken',
        body,
        false,
        {
            key: firebaseApiKey,
        },
        fn.identity,
        fn.identity,
        true,
    );
};

const firebaseChangePassword = ({currentIdToken, newPassword, firebaseApiKey}: FirebaseChangePasswordRequest) => {
    const body = {
        returnSecureToken: true,
        idToken: currentIdToken,
        password: newPassword,
    };

    return fetch.doPost<FirebaseChangePasswordResponse, typeof body>(
        'https://identitytoolkit.googleapis.com/v1/accounts:update',
        body,
        false,
        {
            key: firebaseApiKey,
        },
        fn.identity,
        fn.identity,
        true,
    );
};

// BE will return set-cookie in response header and it will be automatically saved
const sessionLogin = (firebaseToken: string, recaptchaToken: string) =>
    fetch.doPost(getEndpoint('/api/auth/login'), {firebaseToken, recaptchaToken});

const sessionLogout = () => fetch.doPost(getEndpoint('/api/auth/logout'), {});

export const authApi = {
    firebaseLogin,
    firebaseLoginUsingCustomToken,
    firebaseChangePassword,
    sessionLogin,
    sessionLogout,
};
