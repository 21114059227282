import type {Selector} from 'react-redux';

import type {AppState} from 'core/types';
import {app} from 'core/util';
import {SiteEnum} from 'core/enum';

import {NAME} from './constants';
import type {Config} from './types';

const getModel = app.createGetModel(NAME);

const getConfig: Selector<AppState, Config> = (state) => getModel(state).config;
export const getFirebaseApiKey: Selector<AppState, string> = (state) => getConfig(state)?.firebaseApiKey || '';
export const getRecaptchaApiKey: Selector<AppState, string> = (state) => getConfig(state)?.recaptchaApiKey || '';
export const getSite: Selector<AppState, SiteEnum> = (state) => getConfig(state)?.site || SiteEnum.CZE;
