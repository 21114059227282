import type {FunctionComponent} from 'react';
import {connect, MapStateToProps} from 'react-redux';
import invariant from 'invariant';

import type {AppState, NoneOwnProps} from '../types';
import {getCurrentRoute, getCurrentParams} from './selectors';
import {getRegisteredRoutes} from './staticRouteRegister';
import type {RouteParams} from './types';

type ComponentProps = Readonly<{
    currentRoute: string | null;
    currentParams: RouteParams;
}>;

const Component: FunctionComponent<ComponentProps> = ({currentRoute, currentParams}) => {
    const currentRouteDefinition = getRegisteredRoutes()[currentRoute ?? ''];
    invariant(currentRouteDefinition, 'No route to display!');
    invariant(currentRouteDefinition.Container, 'Accessed route has no Container component!');
    return <currentRouteDefinition.Container {...currentParams} />;
};

const mapStateToProps: MapStateToProps<ComponentProps, NoneOwnProps, AppState> = (state) => ({
    currentRoute: getCurrentRoute(state),
    currentParams: getCurrentParams(state),
});

export default connect(mapStateToProps)(Component);
