import {combineReducers, Reducer} from 'redux';

import {ROUTE_ENTERED, SET_LOGIN_REDIRECT_PATH} from './actions';
import type {RouteParams} from './types';

const activeRoute: Reducer<string | null> = (state = null, action) => {
    switch (action.type) {
        case ROUTE_ENTERED:
            return action.name;
        default:
            return state;
    }
};

const activeRouteParams: Reducer<RouteParams> = (state = {}, action) => {
    switch (action.type) {
        case ROUTE_ENTERED:
            return action.params;
        default:
            return state;
    }
};

const loginRedirectPath: Reducer<string | null> = (state = null, action) => {
    switch (action.type) {
        case SET_LOGIN_REDIRECT_PATH:
            return action.path;
        default:
            return state;
    }
};

export default combineReducers({
    activeRoute,
    activeRouteParams,
    loginRedirectPath,
});
