import {NAME} from './constants';
import type {Config} from './types';

const SET_CONFIG = `${NAME}/SET_CONFIG`;

export const configActionGroup = {
    SET_CONFIG,
    setConfig: (config: Config) => ({
        type: SET_CONFIG,
        payload: config,
    }),
};
