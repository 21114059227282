import {Component} from 'react';
import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers,
    Reducer,
    ReducersMapObject,
    Store,
    Action,
    Dispatch,
    AnyAction,
} from 'redux';
import {Provider, batch} from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import i18n from 'core/i18n';
import {ErrorScreen} from 'components/error';

import type {SagaWithReturnType, EmptyObject} from './types';

type AppProviderState = Readonly<{
    error: Error | string | null;
}>;

export type SagaAppContext = {
    addReducer?: (name: string, reducer: Reducer) => void;
};

// TODO fix what with this (error catching) mess?
export default (reducers: ReducersMapObject, saga: SagaWithReturnType) => {
    class AppProvider extends Component<EmptyObject, AppProviderState> {
        private readonly store: Store;

        constructor(props: EmptyObject) {
            super(props);
            this.showError = this.showError.bind(this);
            const sagaContext: SagaAppContext = {};

            /**
             * latency -> redux-dev-tools dropped some actions ( https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#latency )
             */
            /* eslint-disable no-underscore-dangle */
            const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({latency: 10})
                : compose;
            const sagaMiddleware = createSagaMiddleware({
                context: sagaContext,
            });
            const middleware = composeEnhancers(applyMiddleware(reduxBatchMiddleware, sagaMiddleware, reduxBatchMiddleware));
            /* eslint-enable */

            const catchingCombineReducers = (reducersToWrap: ReducersMapObject) => {
                const reducer = combineReducers(reducersToWrap);

                const catchingReducer: Reducer = (state, action) => {
                    try {
                        return reducer(state, action);
                    } catch (e) {
                        this.showError(e);
                        return state;
                    }
                };
                return catchingReducer;
            };

            this.store = createStore(catchingCombineReducers(reducers), middleware);
            let myReducers = reducers;
            const isReducerLoaded = (name: string) => Object.keys(myReducers).includes(name);

            sagaContext.addReducer = (name: string, reducer: Reducer): void => {
                if (!isReducerLoaded(name)) {
                    myReducers = {...myReducers, [name]: reducer};
                    this.store.replaceReducer(catchingCombineReducers(myReducers));
                }
            };

            this.state = {error: null};
            if (saga) {
                const sagaTask = sagaMiddleware.run(saga);
                sagaTask.toPromise().catch((e) => {
                    sagaTask.cancel();
                    this.showError(e);
                });
            }
        }

        componentDidCatch(error: Error) {
            this.showError(error);
        }

        showError(error: Error) {
            console.error(error);
            const stateError = i18n.translateDirectly('error.appNotAvailable');
            // Do not use for normal app. This hack is here only as a helper to simplify patterns for error state of app,
            // since we do not care much about how app continues after error.
            // @ts-ignore - Do not know how to type this
            if (this.updater.isMounted(this)) {
                this.setState({error: stateError});
            } else {
                // eslint-disable-next-line
                this.state = {...this.state, error: stateError};
            }
        }

        render() {
            const {error} = this.state;
            if (error) {
                if (typeof error === 'string') {
                    return <ErrorScreen error={error} />;
                } else {
                    return <ErrorScreen error={error?.message ?? 'Unknown error'} />;
                }
            }
            return <Provider {...this.props} store={this.store} />;
        }
    }

    return AppProvider;
};

const reduxBatchMiddleware = () => (next: Dispatch<AnyAction>) => (action: Action | Action[]) => {
    if (Array.isArray(action)) {
        batch(() => action.forEach(next));
    } else {
        next(action);
    }
};
