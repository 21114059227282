import router from './router';
import notification from './notification';
import i18n from './i18n';
import init from './init';
import fetching from './fetching';
import form from './form';
import auth from './auth';
import user from './user';
import config from './config';
import type {CoreModule} from './types';

const packages: ReadonlyArray<CoreModule> = [router, i18n, init, form, notification, fetching, auth, user, config];

export default packages;
