import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {call, put, select} from 'redux-saga/effects';

import {ADMIN_BOXES, DASHBOARD} from 'app/constants';

import {authApi, usersApi} from '../api';
import user from '../user';
import router from '../router';
import message from '../message';
import type {UserDto} from '../types';
import fetching from '../fetching';
import {fetch, storage, CookieItem} from '../util';
import config from '../config';
import {getRecaptchaToken} from './selectors';
import {recaptchaActionGroup} from './actions';
import {LOGIN_LOADING} from './constants';
import i18n from '../i18n';

export const useRecaptcha = (action: string) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const dispatch = useDispatch();

    const handleRecaptcha = useCallback(async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha(action);
            dispatch({type: recaptchaActionGroup.SET_RECAPTCHA_TOKEN, payload: token});
        }
    }, [executeRecaptcha]);

    useEffect(() => {
        handleRecaptcha();
    }, [handleRecaptcha]);
};

export const loginUsingFirebaseCustomToken = function* (firebaseCustomToken: string) {
    try {
        const firebaseApiKey: string = yield select(config.getFirebaseApiKey);

        const {idToken}: {idToken: string} = yield call(authApi.firebaseLoginUsingCustomToken, {
            customToken: firebaseCustomToken,
            firebaseApiKey,
        });

        const recaptchaToken: string | null = yield select(getRecaptchaToken);
        if (recaptchaToken) {
            yield call(processUserLogin, idToken, recaptchaToken);
        } else {
            yield call(message.show, {translateKey: 'common.recaptcha.failedToInitialize', type: message.MessageType.Error});
        }
    } catch (e) {
        yield call(processLoginErrors, e);
    }
};

export const processUserLogin = function* (idToken: string, recaptchaToken: string) {
    try {
        yield call(authApi.sessionLogin, idToken, recaptchaToken);
        const loggedUser: UserDto = yield call(usersApi.loggedUser);
        yield put(user.userActionGroup.setLoggedUser(loggedUser));

        if (loggedUser.language !== undefined) {
            yield call(storage.set, CookieItem.Language, loggedUser.language);
            i18n.changeLanguage(loggedUser.language);
        }

        const isLoggedUserAdmin: boolean = yield call(usersApi.isLoggedUserAdmin);
        yield put(user.userActionGroup.setIsLoggedUserAdmin(isLoggedUserAdmin));
        const loginRedirectPath: string | null = yield select(router.getLoginRedirectPath);

        if (loginRedirectPath !== null) {
            yield put(router.navigateExternal(loginRedirectPath));
        } else if (isLoggedUserAdmin) {
            yield put(router.navigate(ADMIN_BOXES, true));
        } else {
            yield put(router.navigate(DASHBOARD, true));
        }
        yield put(fetching.stop(LOGIN_LOADING));

        yield call(message.show, {translateKey: 'login.success'});
    } catch (e) {
        yield call(processLoginErrors, e);
    }
};

export const processLoginErrors = function* (e: Error | any) {
    yield put(fetching.stop(LOGIN_LOADING));

    if (e?.response?.error?.message === 'USER_DISABLED') {
        yield call(message.show, {translateKey: 'login.error.inactive', type: message.MessageType.Error});
    } else if (e.response && e.response.globalErrors && e.response.globalErrors.length > 0) {
        yield call(fetch.showGlobalFormErrors, e);
    } else {
        yield call(message.show, {translateKey: 'login.error', type: message.MessageType.Error});
    }
};
