import type {Action} from 'redux';

import {NAME} from './constants';
import type {RouteParams, RouteQuery} from './types';

export const ROUTE_ENTERED = `${NAME}/ROUTE_ENTERED`;
export const NAVIGATE = `${NAME}/NAVIGATE`;
export const NAVIGATE_EXTERNAL = `${NAME}/NAVIGATE_EXTERNAL`;
export const BACK = `${NAME}/BACK`;
export const SET_LOGIN_REDIRECT_PATH = `${NAME}/SET_LOGIN_REDIRECT_PATH`;

export const routeEntered = (name: string, params: RouteParams, query: RouteQuery) => ({
    type: ROUTE_ENTERED,
    name,
    params,
    query,
});

export interface NavigateAction extends Action<typeof NAVIGATE> {
    name: string;
    params: RouteParams;
    query: RouteQuery;
    replace: boolean;
}

export const navigate = (name: string, replace = false, params: RouteParams = {}, query: RouteQuery = {}): NavigateAction => ({
    type: NAVIGATE,
    name,
    replace,
    params,
    query,
});

export interface NavigateExternalAction extends Action<typeof NAVIGATE_EXTERNAL> {
    url: string;
    query: RouteQuery;
}

export const navigateExternal = (url: string, query: RouteQuery = {}): NavigateExternalAction => ({
    type: NAVIGATE_EXTERNAL,
    url,
    query,
});

export interface BackAction extends Action<typeof BACK> {
    steps: number;
}

export const back = (steps = 1): BackAction => ({
    type: BACK,
    steps,
});

export interface SetLoginRedirectAction extends Action<typeof SET_LOGIN_REDIRECT_PATH> {
    path: string;
}

export const setLoginRedirectPath = (path: string): SetLoginRedirectAction => ({
    type: SET_LOGIN_REDIRECT_PATH,
    path,
});
