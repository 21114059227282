import {fetch} from 'core/util';

import {getEndpoint} from './util';
import type {RegisterUserDto, UserUpdateDto, ActivateUserDto, PasswordChangeDto} from './types';
import type {Language} from '../types';

const loggedUser = () =>
    fetch.doGet(getEndpoint('/api/user/me'), true, {}, ({id, uid, name, surname, phoneNumber, email, language}) => ({
        id,
        uid,
        name,
        surname,
        phoneNumber,
        email,
        language,
    }));

const registerUser = (registrationRequest: RegisterUserDto) => fetch.doPost(getEndpoint('/api/user/register'), registrationRequest);

const sendForgottenPasswordEmail = (email: string) => fetch.doPost(getEndpoint('/api/user/reset-password'), email);

const changePassword = (passwordChangeDto: PasswordChangeDto) => fetch.doPut(getEndpoint('/api/user/change-password'), passwordChangeDto);

const activateUser = (activateUserDto: ActivateUserDto) => fetch.doPut(getEndpoint('/api/user/activate'), activateUserDto);

const isLoggedUserAdmin = () => fetch.doGet(getEndpoint('/api/user/is-admin'));

const updateUser = (id: number, userUpdateDto: UserUpdateDto) => fetch.doPut(getEndpoint(`/api/user/${id}`), userUpdateDto);

const updateUserLanguage = (newLanguage: Language) => fetch.doPut(getEndpoint('/api/user/language'), {newLanguage});

export const usersApi = {
    loggedUser,
    registerUser,
    sendForgottenPasswordEmail,
    changePassword,
    activateUser,
    isLoggedUserAdmin,
    updateUser,
    updateUserLanguage,
};
