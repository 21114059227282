import {NAME, NotificationType} from './constants';
import reducer from './reducer';
import Container from './Container';
import {show} from './actions';

export type {NotificationType} from './constants';
const notification = {
    NAME,
    reducer,
    show,
    Container,
    NotificationType,
};

export default notification;
