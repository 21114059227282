import Cookies from 'js-cookie';

export enum CookieItem {
    Language = 'language',
}

const set = (item: CookieItem, value: string | number) => Cookies.set(item, String(value));
const get = (item: CookieItem) => Cookies.get(item);

export const storage = {get, set};
