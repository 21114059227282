import RequestError from './RequestError';
import {doGet, doGetPlain, doPost, doPut, doDelete, timeoutPromise, showGlobalFormErrors} from './fetch';

export default {
    RequestError,
    doGet,
    doPost,
    doPut,
    doDelete,
    doGetPlain,
    showGlobalFormErrors,
    timeoutPromise,
};
