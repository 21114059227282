import {combineReducers, Reducer} from 'redux';

import {recaptchaActionGroup} from './actions';

const recaptchaToken: Reducer<Readonly<string | null>> = (state = null, action) => {
    if (action.type === recaptchaActionGroup.SET_RECAPTCHA_TOKEN) {
        return action.payload;
    } else {
        return state;
    }
};

export default combineReducers({recaptchaToken});
