import type {DeliveryObject} from 'core/types';
import {fetch} from 'core/util';

import {getEndpoint} from './util';

const createDelivery = (deliveryObject: DeliveryObject) => fetch.doPost(getEndpoint('/api/deliveries'), deliveryObject);
const updateDelivery = (deliveryObject: DeliveryObject) => fetch.doPut(getEndpoint(`/api/deliveries/${deliveryObject.id}`), deliveryObject);
const getDeliveries = (boxId: number | null) =>
    fetch.doGet(getEndpoint(`/api/deliveries/box/${boxId}`), true, {}, (deliveries: ReadonlyArray<DeliveryObject>) =>
        deliveries.map(({expectedDeliveryDate, ...delivery}) => ({
            ...delivery,
            expectedDeliveryDate: expectedDeliveryDate?.replace(' ', 'T') || null,
        })),
    );
const markDelivered = (deliveryId: number) => fetch.doPut(getEndpoint(`/api/deliveries/${deliveryId}/delivered`));
const deleteDelivery = (deliveryId: number) => deliveryId && fetch.doDelete(getEndpoint(`/api/deliveries/${deliveryId}`));
const getDeliveryData = (deliveryId: number) => fetch.doGet(getEndpoint(`/api/deliveries/${deliveryId}/delivery-data/`));

export const deliveriesApi = {
    createDelivery,
    updateDelivery,
    getDeliveries,
    markDelivered,
    deleteDelivery,
    getDeliveryData,
};
