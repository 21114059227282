import type {PinObject} from 'core/types';
import {fetch} from 'core/util';

import {getEndpoint} from './util';

const createPin = (pin: PinObject) => fetch.doPost(getEndpoint('/api/pins'), pin);
const updatePin = (pin: PinObject) => fetch.doPut(getEndpoint(`/api/pins/${pin.id}`), pin);
const deletePin = (pinId: number) => fetch.doDelete(getEndpoint(`/api/pins/${pinId}`));
const generatePin = (boxId: number) => fetch.doGet(getEndpoint(`/api/pins/box/${boxId}/generate`));

export const pinsApi = {
    createPin,
    updatePin,
    deletePin,
    generatePin,
};
