import invariant from 'invariant';

import type {ApplicationModule} from './types';

export type Loader<T> = () => Promise<Readonly<{default: T}>>;

export default <T extends ApplicationModule>(loader: Loader<T>) =>
    (): Promise<ApplicationModule> =>
        loader().then((loaded) => {
            const appModule = loaded.default;
            invariant(appModule.NAME, `Dynamically loaded module is missing "NAME" >>>>> ${loader.toString()}`);
            return appModule;
        });
