export type LocaleType = Readonly<{
    id: string;
    languageCode: string;
}>;

export type SupportedLocales = 'sk_SK' | 'cs_CZ' | 'en_US';

export const Locale: Readonly<Record<SupportedLocales, LocaleType>> = Object.freeze({
    sk_SK: {id: 'sk_SK', languageCode: 'SK'},
    cs_CZ: {id: 'cs_CZ', languageCode: 'CS'},
    en_US: {id: 'en_US', languageCode: 'EN'},
});
