import type {Selector} from 'react-redux';

import type {AppState} from 'core/types';
import {app} from 'core/util';

import {NAME} from './constants';

const getModel = app.createGetModel(NAME);

export const getRecaptchaToken: Selector<AppState, string | null> = (state) => getModel(state).recaptchaToken;
