import type {StateEnum} from 'core/enum';
import type {AppState} from 'core/types';

/**
 * Creates "getModel" function for specified module.
 * @param name Name of module which state subtree will be obtained by calling created "getModel" function.
 */
const createGetModel =
    <N extends StateEnum>(name: N) =>
    (state: AppState): AppState[N] => {
        // will be striped in production
        if (process.env.NODE_ENV === 'development') {
            if (!state[name]) {
                throw new Error(`Package "${name}" is not in state. Did you forget to add a reducer?`);
            }
        }
        // @ts-ignore
        return state[name];
    };

export default createGetModel;
