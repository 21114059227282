import {useTranslation, withTranslation, TFunction} from 'react-i18next';

import type {Language} from '../types';
import {NAME} from './constants';
import instance from './instance';

export {Trans} from 'react-i18next';
export type {TFunction};

/**
 * INTERNATIONALIZATION MODULE

 * There is no support for switching yet, but it should be quite simple to add.
 *
 */
export default {
    NAME,
    useTranslation,
    withTranslation,
    addTranslations: (language: string, translations: unknown) =>
        instance.addResourceBundle(language, 'translation', translations, true, false),
    changeLanguage: (lng: string) => new Promise((resolve) => instance.changeLanguage(lng, resolve)),
    getLanguage: () => instance.language as Language,
    translateDirectly: (key: string, ...args: readonly string[]) => instance.t(key, args), // TODO typing of args can be enhanced
};

export * from './constants';
export {getDefaultLanguageBySite} from './util';
