import {createElement, MouseEventHandler, FunctionComponent, MouseEvent} from 'react';

const wrapOnClick = (onClick: MouseEventHandler) => (e: MouseEvent) => {
    if (e.stopPropagation) {
        e.stopPropagation();
    }
    if (e.preventDefault) {
        e.preventDefault();
    }
    onClick(e);
    return false;
};

export type LinkPlainProps = Readonly<{
    href?: string;
    onClick?: MouseEventHandler;
    newTab?: boolean;
    className?: string;
}>;

/**
 * Creates link. When href is not specified, will be rendered as a <span>, otherwise as an <a>.
 */
const LinkPlain: FunctionComponent<LinkPlainProps> = ({children, className, href, onClick, newTab}) => {
    const element = href ? 'a' : 'span';
    const props = {
        className,
        onClick: onClick ? wrapOnClick(onClick) : undefined,
        href: href || undefined,
        target: newTab ? '_blank' : undefined,
        rel: newTab ? 'noopener noreferrer' : undefined, // https://www.owasp.org/index.php/HTML5_Security_Cheat_Sheet#Tabnabbing
    };

    return createElement(element, props, children);
};

export default LinkPlain;
