import {sessionActionGroup, recaptchaActionGroup} from './actions';
import {NAME, LOGIN_LOADING} from './constants';
import reducer from './reducer';
import saga from './saga';
import {getRecaptchaToken} from './selectors';
import {loginUsingFirebaseCustomToken, processLoginErrors, processUserLogin, useRecaptcha} from './utils';

export default {
    NAME,
    LOGIN_LOADING,
    reducer,
    saga,
    sessionActionGroup,
    useRecaptcha,
    recaptchaActionGroup,
    getRecaptchaToken,
    processUserLogin,
    processLoginErrors,
    loginUsingFirebaseCustomToken,
};
