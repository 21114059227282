import {createGlobalStyle, css} from 'styled-components';

import type {Theme} from './types';
import {FontSize, Palette} from './constants';
import {media} from './util';

export * from './util';
export {FontSize, Palette} from './constants';

export const theme: Theme = {
    name: 'Default theme',
    fontFamily: 'sans-serif',
    fontSize: FontSize,
    color: {
        primary: Palette.brandColor60,
        primaryHover: Palette.brandColor40,
        secondary: Palette.accentColor60,
        secondaryHover: Palette.accentColor40,
        secondaryFocus: Palette.accentColor20,
        error: Palette.red,
        warning: Palette.orange,
        ...Palette,
    },
    dimension: {
        inputHeight: FontSize._32,
        inputWidth: '14rem',
        buttonLarge: FontSize._40,
    },
    shadow: {
        light: '0 0 0.25rem rgba(0, 0, 0, 0.25)',
    },
    spacing: {
        mainContentInline: {
            desktop: '1.5rem',
            mobile: '0.25rem',
            mobileIOS: '1rem',
        },
    },
    transition: {
        fast: '250ms ease',
    },
};

export const GlobalStyles = createGlobalStyle<{defaultTheme: Theme}>`
    :root {
        --header-height: 4rem;

        font-family: ${({defaultTheme}) => defaultTheme.fontFamily};
        font-size: 16px;

        ${media.S(css`
            --header-height: 3rem;
        `)}
    }

    // Hiding recaptcha badge
    // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
    .grecaptcha-badge {
        visibility: hidden;
    }

    .ant-select-item {
        display: flex;
        padding: 0 0.5rem;
        align-items: center;
    }
`;
