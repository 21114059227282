import {NAME, NotificationType} from './constants';

export const SHOW = `${NAME}/SHOW`;
export const HIDE = `${NAME}/HIDE`;

export const show = (title: string, text: string, type: NotificationType) => ({
    type: SHOW,
    payload: {title, text, type},
});

export const hide = () => ({
    type: HIDE,
});
