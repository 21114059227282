import {message} from 'antd';
import {v1 as uuidv1} from 'uuid';
import i18next, {TOptions} from 'i18next';

enum MessageType {
    Success = 'success',
    Error = 'error',
    Info = 'info',
    Warning = 'warning',
}

const MINIMAL_DURATION = 5;
const MAXIMAL_DURATION = 15;
const LETTERS_PER_SECOND = 8;

export const limitNumberWithinRange = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

const getMessageDuration = (messageText: string) => {
    const duration = messageText.length / LETTERS_PER_SECOND;
    return limitNumberWithinRange(duration, MINIMAL_DURATION, MAXIMAL_DURATION);
};

const show = ({
    translateKey,
    messageText = '',
    type = MessageType.Success,
    translateOptions = {},
}: {
    translateKey?: string;
    messageText?: string;
    type?: MessageType;
    translateOptions?: TOptions;
}): void => {
    const messageFunction = message[type];
    const key = uuidv1();

    if (!messageText && !translateKey) {
        throw new Error('Message needs messageText or translateKey.');
    }

    const content = translateKey ? i18next.t(translateKey, translateOptions) : messageText;
    const duration = getMessageDuration(content);

    messageFunction({
        content,
        key,
        duration,
        onClick: () => message.destroy(key),
    });
};

export default {show, MessageType};
