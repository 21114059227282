import {combineReducers, Reducer} from 'redux';

import {configActionGroup} from './actions';
import type {Config} from './types';

const config: Reducer<Readonly<Config>> = (state = null, action) => {
    if (action.type === configActionGroup.SET_CONFIG) {
        return action.payload;
    } else {
        return state;
    }
};

export default combineReducers({config});
