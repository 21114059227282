export enum DeliveryState {
    WaitingForDelivery = 'WAITING_FOR_DELIVERY',
    Delivered = 'DELIVERED',
    DeliveredManually = 'DELIVERED_MANUALLY',
}

export enum DeliveryType {
    Incoming = 'INCOMING',
    Outgoing = 'OUTGOING',
}

export type DeliveryObject = Readonly<{
    id: number | null;
    boxId: number;
    name: string;
    pin: string;
    deliveryNumber: string | null;
    carrier: string | null;
    expectedDeliveryDate: string | null;
    deliveryConfirmationCode: string | null;
    deliveryState: DeliveryState;
    deliveredOn: string | null;
    createdOn: string | null;
    synchronized?: boolean | null;
    deliveryType: DeliveryType;
}>;
