import {put, fork, take, cancel, actionChannel, delay} from 'redux-saga/effects';
import type {Task} from 'redux-saga';

import type {SagaWithReturnType} from '../types';
import {SET_FETCHING, setFetchingInternal} from './actions';

const saga: SagaWithReturnType = function* () {
    const channel = yield actionChannel(SET_FETCHING);
    const fetchingTasks: Record<string, Task | null | undefined> = {};

    for (;;) {
        const {
            payload: {entity, fetching, immediate},
        } = yield take(channel);

        if (fetching) {
            if (!fetchingTasks[entity]) {
                fetchingTasks[entity] = yield fork(setFetchingSaga, entity, immediate);
            }
        } else {
            yield put(setFetchingInternal(entity, false));
            const task = fetchingTasks[entity];
            if (task) {
                yield cancel(task);
                fetchingTasks[entity] = null;
            }
        }
    }
};

const setFetchingSaga: SagaWithReturnType<[string, boolean]> = function* (entity, immediate) {
    // wait some ms before setting fetching if not immediate. This will visually help fast loadings.
    if (!immediate) {
        yield delay(150);
    }
    yield put(setFetchingInternal(entity, true));
};

export default saga;
