import {useEffect, ComponentType, FunctionComponent} from 'react';
import {connect, MapStateToProps, MapDispatchToProps, MergeProps} from 'react-redux';
import type {TFunction} from 'react-i18next';

import type {AppState} from 'core/types';
import i18n from 'core/i18n';
import {fn} from 'core/util';

import {hide} from './actions';
import {getShow, getTitle, getText, getType} from './selectors';
import type {NotificationType} from './constants';

export type NotificationProps = Readonly<{
    t: TFunction;
}>;

export type NotificationPlaceholderProps = Readonly<{
    show: boolean;
    title: string;
    text: string;
    type: NotificationType | '';
    onClose: () => void;
}>;

// Replace this component with real notification component
const NotificationPlaceholder: FunctionComponent<NotificationPlaceholderProps> = ({show, title, text, type}) => {
    useEffect(() => {
        if (show) {
            // eslint-disable-next-line no-console
            console.log('Notification: ', show, type, title, text);
        }
    }, [show, title, text, type]);
    return null;
};

type StateProps = Pick<NotificationPlaceholderProps, 'show' | 'title' | 'text' | 'type'>;

const mapStateToProps: MapStateToProps<StateProps, NotificationProps, AppState> = (state) => ({
    show: getShow(state),
    title: getTitle(state),
    text: getText(state),
    type: getType(state),
});

type DispatchProps = Readonly<{
    onClose: () => void;
}>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, NotificationProps> = (dispatch) => ({
    onClose: () => dispatch(hide()),
});

const mergeProps: MergeProps<StateProps, DispatchProps, NotificationProps, NotificationPlaceholderProps> = (
    {show, title, text, type},
    {onClose},
    {t},
) => ({
    show,
    onClose,
    title: t(title),
    text: t(text),
    type,
});

export default fn.compose<ComponentType>(
    i18n.withTranslation(),
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(NotificationPlaceholder);
