import {NAME} from './constants';

const LOGIN = `${NAME}/LOGIN`;
const LOGOUT = `${NAME}/LOGOUT`;
const SET_RECAPTCHA_TOKEN = `${NAME}/SET_RECAPTCHA_TOKEN`;

export const sessionActionGroup = {
    LOGIN,
    LOGOUT,
    login: (email: string, password: string, recaptchaToken: string) => ({
        type: LOGIN,
        payload: {email, password, recaptchaToken},
    }),
    logout: () => ({type: LOGOUT}),
};

export const recaptchaActionGroup = {
    SET_RECAPTCHA_TOKEN,
};
