import queryString from 'query-string';
import {compile} from 'path-to-regexp';

import {getRegisteredRoutes} from './staticRouteRegister';
import type {RouteParams, RouteQuery} from './types';

export default (routeName: string, params: RouteParams = {}, query: RouteQuery = {}): string => {
    const routes = getRegisteredRoutes();
    const route = routes[routeName];
    if (!route) {
        throw new Error(`Route with name ${routeName} is not registered. Use registerRoutes to register route first.`);
    }
    const resolvedPath = compile(route.path)(params);
    const stringQuery = Object.keys(query).length > 0 ? `?${queryString.stringify(query)}` : '';
    return `${resolvedPath}${stringQuery}`;
};
