import {NAME} from './constants';

export const SET_FETCHING = `${NAME}/SET_FETCHING`;
export const SET_FETCHING_INTERNAL = `${NAME}/SET_FETCHING/INTERNAL`;
export const CLEAR = `${NAME}/CLEAR`;

/**
 * Sets fetching status for some entity. When immediate flag is not set, will by default wait
 * for around 150ms before starting - for situations when set and unset goes fast after each other.
 * If unset goes within theese 150ms, fetching status is not set at all! (so no re-render) Unset is called immediately always.
 * @param {String} entity
 * @param {Boolean} fetching
 * @param {Boolean} [immediate=false] Set if you expect most of the time, loading will be longer than around 150ms
 */
export const setFetching = (entity: string, fetching: boolean, immediate = false) => ({
    type: SET_FETCHING,
    payload: {entity, fetching, immediate},
});

/**
 * Used for clearing true/false value, to be able to select default value again
 */
export const clear = (entity: string) => ({
    type: CLEAR,
    payload: {entity},
});

export const start = (entity: string) => setFetching(entity, true, false);
export const startImmediate = (entity: string) => setFetching(entity, true, true);
export const stop = (entity: string) => setFetching(entity, false);

export const setFetchingInternal = (entity: string, fetching: boolean) => ({
    type: SET_FETCHING_INTERNAL,
    payload: {entity, fetching},
});
