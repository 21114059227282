import {combineReducers, Reducer} from 'redux';

import {SHOW, HIDE} from './actions';
import type {NotificationType} from './constants';

const show: Reducer<boolean> = (state = false, {type}) => {
    switch (type) {
        case SHOW:
            return true;
        case HIDE:
            return false;
        default:
            return state;
    }
};

const title: Reducer<string> = (state = '', {type, payload}) => {
    switch (type) {
        case SHOW:
            return payload.title;
        case HIDE:
            return '';
        default:
            return state;
    }
};

const text: Reducer<string> = (state = '', {type, payload}) => {
    switch (type) {
        case SHOW:
            return payload.text;
        case HIDE:
            return '';
        default:
            return state;
    }
};

const notificationType: Reducer<NotificationType | ''> = (state = '', {type, payload}) => {
    switch (type) {
        case SHOW:
            return payload.type;
        case HIDE:
            return '';
        default:
            return state;
    }
};

export default combineReducers({
    show,
    title,
    text,
    notificationType,
});
