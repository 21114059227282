import {BoxState} from '../types';
import {Palette} from '../theme';

export {default as app} from './app';
export {default as fetch} from './fetch';
export {default as fn} from './fn';
export {default as format} from './format';
export {default as model} from './model';
export * from './constants';
export * from './storage';

export const getColorFromState = (offline: boolean, boxState: BoxState) => {
    if (boxState === BoxState.Unregistered) {
        return Palette.gray40;
    } else if (offline) {
        return Palette.red;
    } else {
        return Palette.green;
    }
};

export const createIsEnum =
    <T>(e: T) =>
    // eslint-disable-next-line
    (token: any): token is T[keyof T] =>
        Object.values(e).includes(token as T[keyof T]);
