import type {HttpStatusCode} from 'core/enum';

export type ErrorInfo = Readonly<{
    status: HttpStatusCode;
    response?: Readonly<{
        body: any;
        fieldErrors?: string[];
    }>;
}>;

class RequestError extends Error {
    public readonly status: HttpStatusCode;

    public readonly response?: string;

    public readonly wasDataModifying: boolean;

    public readonly fieldErrors?: string[];

    constructor(errorInfo: ErrorInfo, wasDataModifying: boolean) {
        super();

        this.name = 'RequestError';
        this.status = errorInfo.status;
        this.response = errorInfo.response?.body;
        this.wasDataModifying = wasDataModifying;
        this.fieldErrors = errorInfo.response?.body?.fieldErrors;
    }
}

export default RequestError;
