export enum StateEnum {
    coreAuth = 'core-auth',
    coreFetching = 'core-fetching',
    coreInit = 'core-init',
    coreNotification = 'core-notification',
    coreRouter = 'core-router',
    coreUser = 'core-user',
    coreForm = 'core-form',
    coreConfig = 'core-config',
    boxDetail = 'box-detail',
    activateUser = 'activate-user',
    passwordReset = 'password-reset',
    adminBoxes = 'admin-boxes',
    adminUsers = 'admin-users',
    login = 'login',
}

export enum HttpStatusCode {
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
}

export enum SiteEnum {
    BEL = 'BEL',
    CZE = 'CZE',
}

export {Locale} from './Locale';
