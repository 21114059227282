import type {Reducer} from 'redux';

import type {ApplicationModule, TypedObject} from './types';

export default (packages: ReadonlyArray<ApplicationModule>, extraReducers: TypedObject<Reducer> = {}) => {
    const packageReducers = packages
        .filter((module) => module.reducer)
        .reduce((result, module) => ({[module.NAME]: module.reducer, ...result}), {});
    return {
        ...packageReducers,
        ...extraReducers,
    };
};
