import {NAME} from './constants';
import {setFetching, startImmediate, start, stop, clear} from './actions';
import {isFetching} from './selectors';
import reducer from './reducer';
import saga from './saga';

export default {
    NAME,
    setFetching,
    start,
    stop,
    startImmediate,
    isFetching,
    reducer,
    saga,
    clear,
    fetchingString: '...',
};
