import type {Selector} from 'react-redux';

import {app} from 'core/util';
import type {AppState} from 'core/types';

import {NAME, NotificationType} from './constants';

const getModel = app.createGetModel(NAME);

export const getShow: Selector<AppState, boolean> = (state) => getModel(state).show;
export const getTitle: Selector<AppState, string> = (state) => getModel(state).title;
export const getText: Selector<AppState, string> = (state) => getModel(state).text;
export const getType: Selector<AppState, NotificationType | ''> = (state) => getModel(state).notificationType;
