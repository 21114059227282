import type {Reducer} from 'redux';
import {produce} from 'immer';

import {EMPTY_OBJECT} from 'core/util';

import type {CoreFetchingState} from './types';
import {SET_FETCHING_INTERNAL, CLEAR} from './actions';

const reducer: Reducer<CoreFetchingState> = (state = EMPTY_OBJECT, action) => {
    switch (action.type) {
        case SET_FETCHING_INTERNAL:
            return produce(state, (draft) => {
                const {entity, fetching} = action.payload;
                draft[entity] = fetching;
            });
        case CLEAR:
            return produce(state, (draft) => {
                delete draft[action.payload.entity];
            });
        default:
            return state;
    }
};

export default reducer;
