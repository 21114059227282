import {fetch} from 'core/util';
import type {RegisterBoxDto, BoxObject} from 'core/types';

import {getEndpoint} from './util';

const getBoxes = () => fetch.doGet(getEndpoint('/api/boxes'));
const registerBox = (registerBoxRequest: RegisterBoxDto) => fetch.doPost(getEndpoint('/api/boxes'), registerBoxRequest);
const unregisterBox = (boxId: number) => fetch.doPut(getEndpoint(`/api/boxes/${boxId}/unregister`));
const updateBox = ({id: boxId, ...box}: Omit<BoxObject, 'serialNumber' | 'pins' | 'offline' | 'boxState'>) =>
    fetch.doPut(getEndpoint(`/api/boxes/${boxId}`), box);

export const boxesApi = {
    getBoxes,
    registerBox,
    unregisterBox,
    updateBox,
};
