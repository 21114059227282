import {configActionGroup} from './actions';
import {NAME} from './constants';
import reducer from './reducer';
import {getRecaptchaApiKey, getFirebaseApiKey, getSite} from './selectors';

export default {
    NAME,
    reducer,
    configActionGroup,
    getFirebaseApiKey,
    getRecaptchaApiKey,
    getSite,
};
