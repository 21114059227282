import {all, fork, call} from 'redux-saga/effects';

import {fn} from 'core/util';

import type {SagaWithReturnType, CoreModule} from './types';

type SagaWrapperObject<P extends unknown[] = []> = Readonly<{
    saga: SagaWithReturnType<P>;
}>;

type SagaParamTuple<P> = [SagaWrapperObject<[P]>, P];

export type BackgroundSagaPackages = ReadonlyArray<CoreModule | SagaParamTuple<unknown>>;

/**
 * @param backgroundSagaPackages - array of either objects with shape {saga}, or arrays of shape [{saga}, param].
 *      If array, will pass second element as an argument to saga.
 * @param initSaga Initial application saga which is started after background sagas are started.
 * @param initSagaArguments Parameters for initial saga.
 */
export default <P>(
    backgroundSagaPackages: BackgroundSagaPackages,
    initSaga: SagaWithReturnType<[P]>,
    initSagaArguments: P,
): SagaWithReturnType =>
    function* () {
        const sagas = backgroundSagaPackages.map((pckg) => {
            if (Array.isArray(pckg)) {
                return fork(pckg[0].saga, pckg[1]);
            }
            if (pckg.saga) {
                return fork(pckg.saga);
            }
            return fork(fn.noop);
        });
        yield all(sagas);
        if (initSaga) {
            yield call(initSaga, initSagaArguments);
        }
    };
