import {takeEvery, cancel, call, fork, put, delay, select, getContext, takeLatest, all} from 'redux-saga/effects';
import {eventChannel, Task} from 'redux-saga';
import NProgress from 'nprogress';
import {createBrowserHistory, Location} from 'history';
import {match} from 'path-to-regexp';
import {parse, stringify} from 'query-string';
import type {StrictEffect} from '@redux-saga/types';
import type {SetOptional} from 'type-fest';
import type {Action} from 'redux';

import {ERROR, DASHBOARD} from 'app/constants';
import notification from 'core/notification';

import type {SagaAppContext} from '../createProvider';
import type {SagaWithReturnType, ExactTypedObject, SagaYieldReturn} from '../types';
import {NAVIGATE, NAVIGATE_EXTERNAL, BACK, routeEntered, back, BackAction, NavigateAction, NavigateExternalAction} from './actions';
import {getCurrentRoute, getCurrentParams} from './selectors';
import {getRegisteredRoutes, setComponent} from './staticRouteRegister';
import getStaticUrl from './getStaticUrl';
import {routerWrapper} from './utils';
import type {Route, RouteParams, RouteQuery, ClearDataActionProducer, RouterWrapper, RouteEnteredHandler} from './types';

export const history = createBrowserHistory();

const routerSaga: SagaWithReturnType = function* () {
    yield takeEvery(NAVIGATE, onNavigate);
    yield takeEvery(NAVIGATE_EXTERNAL, onNavigateExternal);
    yield takeEvery(BACK, onBack);
};

export default routerSaga;

// it seems browser will trigger initial path in listen too, but for initial page load, we want to call setPageByLocationDirectly
export function* startRouting() {
    const historyChannel = createHistoryChannel();
    yield takeLatest(historyChannel, loadPage);
}

export const setPageByLocationDirectly: SagaWithReturnType<[Location]> = function* (location) {
    const resolved = parseLocation(location);
    if (resolved === null) {
        yield call([history, history.push], '/');
    }
    yield call(loadPage, resolved || {name: DASHBOARD}, true);
};

function createHistoryChannel() {
    return eventChannel((emitter) => {
        const unlisten = history.listen((location) => {
            const resolved = parseLocation(location);
            if (resolved) {
                emitter(resolved);
            } else {
                history.push('/');
            }
        });
        return () => unlisten();
    });
}

const onNavigate: SagaWithReturnType<[NavigateAction]> = function* ({name, params, query, replace}) {
    const historyOperation = replace ? history.replace : history.push;
    yield call([history, historyOperation], getStaticUrl(name, params, query));
};

const onNavigateExternal: (action: NavigateExternalAction) => Generator<string, void, void> = function* ({url, query}) {
    const stringQuery = Object.keys(query).length > 0 ? `?${stringify(query)}` : '';
    yield (window.location.href = `${url}${stringQuery}`);
};

let previousRouteClear: ClearDataActionProducer | null = null;
let previousPageSagaTask: Task | null = null;
let progressTask: Task | null = null;

function* loadPage(
    {name: newRoute, params = {}, query = {}}: SetOptional<ParsedLocation, 'params' | 'query'>,
    initialLoad = false,
): Generator<StrictEffect, void, any> {
    // Cannot be typed by SagaWithReturnType because it is recursive
    const currentRoute: SagaYieldReturn<typeof getCurrentRoute> = yield select(getCurrentRoute);
    const currentParams: SagaYieldReturn<typeof getCurrentParams> = yield select(getCurrentParams);

    if (currentRoute !== newRoute || objectsShallowlyDifferent(currentParams, params)) {
        if (!initialLoad) {
            progressTask = yield fork(delayedProgressStart, 200);
        }
        try {
            const routes = getRegisteredRoutes();
            const lazyPackage = routes[newRoute]?.lazyPackage;
            const addReducer: SagaAppContext['addReducer'] = yield getContext('addReducer'); // from createProvider

            if (!lazyPackage) {
                throw new Error(`Route ${newRoute} does not define lazyPackage loading function.`);
            }

            const {NAME, saga, reducer, Container}: SagaYieldReturn<typeof lazyPackage> = yield call(lazyPackage);
            let packageSaga: RouterWrapper;
            if (!saga) {
                packageSaga = routerWrapper({});
            } else if (typeof saga === 'function') {
                packageSaga = routerWrapper({
                    onPageEnter: saga,
                });
            } else {
                packageSaga = saga;
            }

            if (Container) {
                setComponent(newRoute, Container);
            }

            if (reducer && addReducer) {
                addReducer(NAME, reducer);
            }

            const [pageInitActions, dataPutActions]: [readonly Action[], readonly Action[]] = yield all([
                call(packageSaga.initPageState, params, query),
                call(packageSaga.getDataForPage, params, query),
            ]);

            yield put([...pageInitActions, ...dataPutActions, routeEntered(newRoute, params, query)]);
            yield call(cancelPreviousSagaTask);
            yield call(cancelProgressTask);
            yield call(callPreviousPageClearIfDifferentRoute, currentRoute, newRoute, packageSaga.clearDataForPage);

            try {
                yield call(forkPageSaga, packageSaga.onPageEnter, params, query);
            } catch (e) {
                console.error(e);
            }
        } catch (e) {
            if (initialLoad) {
                // TODO :: redirect on error page
                yield call(loadPage, {name: ERROR});
                console.error(e);
            } else {
                yield put(back());
                yield put(notification.show('error.page.title', 'error.page.text', notification.NotificationType.FAILED));
                console.error(e);
            }
        } finally {
            yield call(cancelProgressTask);
        }
    }
}

const callPreviousPageClearIfDifferentRoute: SagaWithReturnType<[string | null, string | null, ClearDataActionProducer]> = function* (
    currentRoute,
    newRoute,
    clearDataForPage,
) {
    // if i am at the same route with different data, data are replaced.
    // Only if i go to different page type, clear data of previous that page;
    if (currentRoute && currentRoute !== newRoute) {
        if (previousRouteClear) {
            try {
                const clearActions: SagaYieldReturn<typeof previousRouteClear> = yield call(previousRouteClear);
                yield put(clearActions);
            } catch (e) {
                // TODO just ignore error in clear blocks?
            }
        }
    }
    previousRouteClear = clearDataForPage;
};

function* cancelPreviousSagaTask() {
    if (previousPageSagaTask) {
        yield cancel(previousPageSagaTask);
        previousPageSagaTask = null;
    }
}

// new function to allow forkPageSaga to be forkable/catcheable
const forkPageSaga: SagaWithReturnType<[RouteEnteredHandler, RouteParams, RouteQuery]> = function* (saga, params, query) {
    if (saga) {
        // Fork is here to avoid automatic cancellation (takeLatest). We want to cancel this saga after routeEntered(name, params, query)
        // to avoid page blinking when somebody is clearing some state on page saga cancellation (=backwards compatible behavior)
        previousPageSagaTask = yield fork(saga, params, query);
    }
};

export const cancelProgressTask: SagaWithReturnType = function* () {
    if (progressTask) {
        yield cancel(progressTask);
    }
    yield call([NProgress, NProgress.done]);
};

export const delayedProgressStart: SagaWithReturnType<[number]> = function* (ms) {
    yield delay(ms);
    yield call([NProgress, NProgress.start]);
};

const onBack: SagaWithReturnType<[BackAction]> = function* ({steps}) {
    yield call([history, history.go], -1 * steps);
};

function objectsShallowlyDifferent(firstObj: unknown, secondObj: unknown) {
    if (typeof firstObj !== 'object' || firstObj === null || typeof secondObj !== 'object' || secondObj === null) {
        return false;
    }

    return Object.entries(secondObj).some(([key, value]) => (firstObj as Record<string, unknown>)[key] !== value);
}

type ParsedLocation = Readonly<{
    /** Name of route matching with parsed location. */
    name: string;
    params: RouteParams;
    query: RouteQuery;
}>;

function parseLocation(location: Location): ParsedLocation | null {
    const routes = getRegisteredRoutes();
    const routeEntry = Object.entries(routes as ExactTypedObject<Route>).find(([_key, {path}]) => {
        const result = match(path, {decode: decodeURIComponent});
        return result(location.pathname) !== false;
    });
    if (!routeEntry) {
        return null;
    }
    const name = routeEntry[0];
    const matchedObject = match<RouteParams>(routes[name]?.path ?? '')(location.pathname);
    if (matchedObject) {
        return {
            name,
            params: matchedObject.params,
            query: parse(location.search),
        };
    } else {
        throw new Error(`There is no match for location pathname ${location.pathname} and route path ${routes[name]?.path}`);
    }
}
