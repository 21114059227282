import {compose} from 'redux';

import type {Truthy, WithFalsy, GenericObject} from 'core/types';

export default {
    noop: (): void => {},
    /**
     * Creates array from specified parameters and filters out any falsy values.
     * @param values Parameters from which array is created.
     * @example
     * const result1 = array('abc', false, 'cde', null, undefined, ''); // result1 is ['abc', 'cde']
     *
     * // if you need heterogeneous parameter types then you have to specify their type union as parameter for generic
     * const result2 = array<string | number>('abc', false, 'cde', null, undefined, '', 6); // result is ['abc', 'cde', 6]
     */
    array: <T>(...values: ReadonlyArray<WithFalsy<T>>) => values.filter((value): value is Truthy<T> => Boolean(value)),
    identity: <T>(item: T): T => item,
    block: (): Promise<void> => new Promise(() => {}),
    isEmpty: (value: unknown): boolean => typeof value === 'undefined' || value === null || value === '',
    compose,
    toObject: (result: GenericObject, [key, value]: [string, unknown]): GenericObject => ({[key]: value, ...result}),
    sum: (acc: number, number: number): number => acc + number,
    /**
     * Checks if maybeKey is key of specified object. Works as type guard for indexing.
     * @param object Inspected object.
     * @param maybeKey Value which may be key of object.
     */
    isObjectKey: <T>(object: T, maybeKey: unknown): maybeKey is keyof T => {
        return !!maybeKey && typeof maybeKey === 'string' && Object.keys(object).includes(maybeKey);
    },
    insertString: (originalString: string, index: number, insertedString: string): string =>
        originalString.length >= index
            ? `${originalString.substr(0, index)}${insertedString}${originalString.substring(index)}`
            : originalString,
};
