import type {FunctionComponent, PropsWithChildren} from 'react';
import {connect, MapDispatchToProps} from 'react-redux';

import {HyperLink} from 'components';

import type {RouteParams, RouteQuery} from './types';
import getStaticUrl from './getStaticUrl';
import {navigate} from './actions';

type RouteClickHandler = (routeName: string, params?: RouteParams, query?: RouteQuery) => void;

export type LinkProps = PropsWithChildren<
    Readonly<{
        route?: string;
        params?: RouteParams;
        query?: RouteQuery;
        to?: string;
        plain?: boolean;
        disabled?: boolean;
        className?: string;
        onClick: RouteClickHandler;
    }>
>;

const Link: FunctionComponent<LinkProps> = ({route, params, query, to, plain, disabled = false, className, children, onClick}) => {
    const passedProps = {
        plain,
        disabled,
        className,
        children,
        // Add other needed props
    };

    const url = to || (route ? getStaticUrl(route, params, query) : '');
    if (process.env.NODE_ENV === 'development' && !url) {
        // eslint-disable-next-line no-console
        console.warn('no url for Link!', {route, params, query, to});
    }
    return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <HyperLink
            {...passedProps}
            href={url}
            onClick={(e) => {
                if (disabled) {
                    e.preventDefault();
                    return false;
                }
                if (e.stopPropagation) {
                    e.stopPropagation();
                }
                if (e.preventDefault) {
                    e.preventDefault();
                }
                if (route) {
                    onClick(route, params, query);
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('Route is not defined. Cannot navigate to it.');
                }

                return false;
            }}
        />
    );
};

type DispatchProps = Readonly<{
    onClick: RouteClickHandler;
}>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Omit<LinkProps, 'onClick'>> = (dispatch) => ({
    onClick: (route, params, query) => dispatch(navigate(route, false, params, query)),
});

export default connect(undefined, mapDispatchToProps)(Link);
