import {StateEnum} from 'core/enum';

export const NAME = StateEnum.coreNotification;

export enum NotificationType {
    DANGER = 'danger',
    INFO = 'info',
    SUCCESS = 'success',
    FAILED = 'failed',
}
