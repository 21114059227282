import {css, FlattenSimpleInterpolation} from 'styled-components';

export enum Breakpoints {
    'XXS' = '340px',
    'XS' = '380px',
    'S' = '520px',
    'M' = '768px',
    'L' = '1024px',
    'XL' = '1280px',
    'XXL' = '1440px',
}

type CreateMediaQuery = (breakPoint: Breakpoints) => (style: FlattenSimpleInterpolation) => FlattenSimpleInterpolation;

const createMediaQuery: CreateMediaQuery = (breakPoint) => (style) =>
    css`
        @media (max-width: ${breakPoint}) {
            ${style}
        }
    `;

export const media = {
    XXS: createMediaQuery(Breakpoints.XXS),
    XS: createMediaQuery(Breakpoints.XS),
    S: createMediaQuery(Breakpoints.S),
    M: createMediaQuery(Breakpoints.M),
    L: createMediaQuery(Breakpoints.L),
    XL: createMediaQuery(Breakpoints.XL),
    XXL: createMediaQuery(Breakpoints.XXL),
} as const;
