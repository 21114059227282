// @ts-nocheck
import {useRef} from 'react';
import {v1 as uuidv1} from 'uuid';

import i18n from 'core/i18n';

import {TO_TRANSLATE_PREFIX} from './validation';

const FormField = (props) => {
    const idRef = useRef(uuidv1());
    const {t} = i18n.useTranslation();

    /**
     * Ugly ugly hack, but best performance. We change INSIDE of the passed object, so the reference is still same.
     */
    // eslint-disable-next-line no-param-reassign,react/destructuring-assignment
    props.meta.error = (props.meta.error || []).map((msg) => {
        if (msg.startsWith(TO_TRANSLATE_PREFIX)) {
            return t(msg.replace(TO_TRANSLATE_PREFIX, ''));
        }
        return msg;
    });

    const {layoutComponent: LayoutComponent, inputComponent: InputComponent, ...rest} = props;

    return (
        <LayoutComponent {...rest} id={idRef.current}>
            <InputComponent {...rest} id={idRef.current} />
        </LayoutComponent>
    );
};

export default FormField;
