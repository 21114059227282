import type {Selector} from 'react-redux';

import type {AppState, UserDto} from 'core/types';
import {app} from 'core/util';

import {NAME} from './constants';

const getModel = app.createGetModel(NAME);

export const getUser: Selector<AppState, UserDto | null> = (state) => getModel(state).loggedUser;
export const isLoggedIn: Selector<AppState, boolean> = (state) => getUser(state) !== null;
export const getIsLoggedUserAdmin: Selector<AppState, boolean> = (state) => getModel(state).isLoggedUserAdmin;
