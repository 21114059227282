import {format, isToday, isTomorrow} from 'date-fns';

import {SiteEnum} from 'core/enum';

import {
    DISPLAY_DATE_FORMAT_WITH_TIME_BEL,
    DISPLAY_DATE_FORMAT_WITH_TIME_CZE,
    DISPLAY_DATE_FORMAT_WITHOUT_TIME_BEL,
    DISPLAY_DATE_FORMAT_WITHOUT_TIME_CZE,
} from '../constants';
import i18n from '../i18n';

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;

const round = (value: number, decimal = 0): number => Math.round(value * 10 ** decimal) / 10 ** decimal;

const duration = (seconds: number) => {
    const hours = Math.floor(seconds / SECONDS_IN_HOUR);
    const minutes = Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
    const remainingSeconds = seconds % SECONDS_IN_MINUTE;

    return `${hours > 0 ? `${hours} h ` : ''}${minutes > 0 ? `${minutes} min ` : ''}${remainingSeconds > 0 ? `${remainingSeconds} s` : ''}`;
};

const weight = (grams: number) => (grams > 1000 ? `${round(grams / 1000, 2)} kg` : `${grams} g`);

const normalizeInteger = (value: string, prevValue: string): string =>
    Number.isNaN(Number(value.replace(' ', ''))) ? prevValue : value.trim().replace('.', '');

export const getDateFormatWithTime = (site: SiteEnum) =>
    site === SiteEnum.BEL ? DISPLAY_DATE_FORMAT_WITH_TIME_BEL : DISPLAY_DATE_FORMAT_WITH_TIME_CZE;

export const getDateFormatWithoutTime = (site: SiteEnum) =>
    site === SiteEnum.BEL ? DISPLAY_DATE_FORMAT_WITHOUT_TIME_BEL : DISPLAY_DATE_FORMAT_WITHOUT_TIME_CZE;

const renderDateWithTime = (date: string | null, site: SiteEnum) => renderDate(date, getDateFormatWithTime(site));

export const renderDate = (date: string | null, dateFormat: string) => {
    if (date !== null && date !== undefined) {
        return format(new Date(date), dateFormat);
    } else {
        return '';
    }
};

/**
 * Formats given date with following rule: If the date is today or tomorrow, it shows "Today" or "Tomorrow",
 * otherwise normal formatted date is shown.
 * todo make it call renderDateRelative only in case the delivered state is WAITING_FOR_DELIVERY
 */
const renderDateRelative = (originalDateString: string | null | undefined, site: SiteEnum) => {
    if (!originalDateString) {
        return '';
    }

    const date = new Date(originalDateString);

    if (isToday(date)) {
        return i18n.translateDirectly('dashboard.delivery.delivered.today');
    } else if (isTomorrow(date)) {
        return i18n.translateDirectly('dashboard.delivery.delivered.tomorrow');
    } else {
        return renderDate(originalDateString, getDateFormatWithoutTime(site));
    }
};

export default {
    round,
    duration,
    weight,
    normalizeInteger,
    renderDateWithTime,
    renderDateRelative,
    renderDate,
    getDateFormatWithTime,
    getDateFormatWithoutTime,
};
