import superagent, {SuperAgentRequest} from 'superagent';
import {call, fork} from 'redux-saga/effects';
import type {StrictEffect} from '@redux-saga/types';

import type {SagaYieldReturn} from 'core/types';
import {HttpStatusCode} from 'core/enum';
import message from 'core/message';
import {Language} from 'core/types';

import fn from '../fn';
import RequestError from './RequestError';
import UnauthorizedError from './UnauthorizedError';
import {storage, CookieItem} from '../storage';

/**
 * Function which process response body and converts it into result.
 * Important: Type was modified with "any" to allow for fn.identity as default value because of time.
 * Should not be replicated in other projects.
 * @template R Type of result.
 */
export type ResultProcessMethod<R> = (responseBody: any) => R;

/**
 * Function which process body parameter and prepares it to be send by superagent.
 * Important: Type was modified with "any" to allow for fn.identity as default value because of time.
 * Should not be replicated in other projects.
 */
export type RequestProcessMethod = (requestBody: any) => any;

export type QueryParams = Parameters<SuperAgentRequest['query']>[0];

const execute = (request: SuperAgentRequest): Promise<unknown> =>
    request.then(
        (response) => response.body,
        (error) => {
            if (error.status === 401) {
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw new UnauthorizedError();
            } else if (error.status) {
                // Apparently, superagent returns (json?) parse error here. Original response is in field "rawResponse".
                if (error.rawResponse) {
                    /* eslint-disable no-console */
                    console.warn(error.rawResponse);
                    /* eslint-disable no-console */
                }
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw new RequestError(error, request.method !== 'GET');
            } else {
                // some weird errors here, plus terminated by browser
                console.error(error);
                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw new RequestError(
                    {
                        status: HttpStatusCode.INTERNAL_SERVER_ERROR,
                        response: {body: 'some weird error here. See console output before this'},
                    },
                    request.method !== 'GET',
                );
            }
        },
    );

const getLanguageHeaderValue = (language?: string): string => {
    switch (language) {
        case Language.en:
            return 'en';
        case Language.de:
            return 'de';
        default:
            return 'cs';
    }
};

const wrapExecute = function* <RT>(
    request: SuperAgentRequest,
    withCredentials: boolean,
    resultProcessMethod: ResultProcessMethod<RT>,
    withoutLanguage: boolean,
): Generator<StrictEffect, RT, any> {
    // eslint-disable-next-line no-useless-catch
    try {
        const language = storage.get(CookieItem.Language);
        const rqWithLanguage = withoutLanguage ? request : request.set('language', getLanguageHeaderValue(language));
        const rqWithCredentials = withCredentials ? rqWithLanguage.withCredentials() : rqWithLanguage;

        const result: SagaYieldReturn<typeof execute> = yield call(execute, rqWithCredentials);
        return resultProcessMethod(result);
    } catch (error) {
        throw error;
    }
};

/**
 * Performs http get request and returns result from resultProcess method. Error is thrown when request is not successful.
 * @param url Url to call.
 * @param withCredentials Enables the ability to send cookies from the origin.
 * @param queryParams Query parameters as defined by superagent https://visionmedia.github.io/superagent/#query-strings
 * @param resultProcessMethod Function which process response body and converts it into result.
 * @param withoutLanguage Enables sending without language field in header.
 *
 * @example
 * // Call in saga with use of yield* can infer return value type from specified resultProcessMethod.
 * const resultProcessMethod: ResultProcessMethod<string> = (responseBody) => 'I am string no matter what!';
 *
 * const exampleSaga: SagaWithReturnType = function*(){
 *     const result = yield* doGet("url", "params", resultProcessMethod); // type of result is correctly inferred to string
 * }
 *
 * @example
 * // Call in saga with use of yield has no way to infer type from resultProcessMethod, so we must use resultProcessMethod in SagaYieldReturn instead of doGet.
 * const resultProcessMethod: ResultProcessMethod<string> = (responseBody) => 'I am string no matter what!';
 *
 * const exampleSaga: SagaWithReturnType = function*(){
 *     // To get correct type we must use typeof resultProcessMethod instead of typeof doGet.
 *     const result: SagaYieldReturn<typeof resultProcessMethod> = yield call(doGet, "url", "params", resultProcessMethod); // type of result is string
 * }
 */
export const doGet = function* <RT>(
    url: string,
    withCredentials: boolean = true,
    queryParams: QueryParams = {},
    resultProcessMethod: ResultProcessMethod<RT> = fn.identity,
    withoutLanguage: boolean = false,
): Generator<StrictEffect, RT, any> {
    const request = superagent.get(url).query(queryParams).accept('json');
    return yield* wrapExecute(request, withCredentials, resultProcessMethod, withoutLanguage);
};

export const doGetPlain = function (url: string): Promise<string> {
    return superagent.get(url).then(
        (response) => response.text,
        (error) => {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new RequestError(error, false);
        },
    );
};

export const doPut = function* <RT, B>(
    url: string,
    body: B | null = null,
    withCredentials: boolean = true,
    queryParams: QueryParams = {},
    requestProcessMethod: RequestProcessMethod = fn.identity,
    resultProcessMethod: ResultProcessMethod<RT> = fn.identity,
    withoutLanguage: boolean = false,
) {
    const request = superagent.put(url).send(requestProcessMethod(body)).query(queryParams).type('json').accept('json');
    return yield* wrapExecute(request, withCredentials, resultProcessMethod, withoutLanguage);
};

export const doPost = function* <RT, B>(
    url: string,
    body: B,
    withCredentials: boolean = true,
    queryParams: QueryParams = {},
    requestProcessMethod: RequestProcessMethod = fn.identity,
    resultProcessMethod: ResultProcessMethod<RT> = fn.identity,
    withoutLanguage: boolean = false,
): Generator<StrictEffect, RT, any> {
    const request = superagent.post(url).send(requestProcessMethod(body)).query(queryParams).type('json').accept('json');
    return yield* wrapExecute(request, withCredentials, resultProcessMethod, withoutLanguage);
};

export const doDelete = function* <RT>(
    url: string,
    withCredentials: boolean = true,
    resultProcessMethod: ResultProcessMethod<RT> = fn.identity,
    withoutLanguage: boolean = false,
): Generator<StrictEffect, RT, any> {
    const request = superagent.delete(url).accept('json');
    return yield* wrapExecute(request, withCredentials, resultProcessMethod, withoutLanguage);
};

/**
 * Utility for mocking delayed apis.
 * @param result Value which is going to be resolved by promise after specified timeout.
 * @param timeout Number of milliseconds for delaying promise resolve.
 * @example
 * export const myCall = () => fetch.timeoutPromise("ok", 2000);
 */
export const timeoutPromise = <R>(result: R, timeout: number): Promise<R> =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(result);
        }, timeout);
    });

export const showGlobalFormErrors = function* (exception: any): Generator<StrictEffect, void, any> {
    if (exception.response && exception.response.globalErrors && exception.response.globalErrors.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const error of exception.response.globalErrors) {
            yield fork(message.show, {messageText: error, type: message.MessageType.Error});
        }
    }
};
