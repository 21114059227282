// @ts-nocheck
import type {TypedObject} from '../types';
import reduxForm from './reduxForm';

const InnerForm = ({handleSubmit, reset, submitting, dirty, error, formComponent, initialized, ownProps}) => {
    const FormComponent = formComponent;
    const formProps = {
        errors: error,
        handleSubmit,
        reset,
        canSubmit: !submitting && dirty,
        canReset: !submitting,
        submitting,
        initialized,
        // do not pass more arguments from redux-form here carelessly. For example, form-wide valid prop means whole form
        // re-renders when some field changes validit. Epecially ones under ConditionalSection
        // if adding something here, definitely test theese!
    };
    return <FormComponent {...formProps} {...ownProps} />;
};

export default (formName: string, config?: TypedObject<unknown>) => (formComponent) => {
    const InnerFormComponent = reduxForm({
        ...config,
        destroyOnUnmount: false,
        form: formName,
    })((props) => <InnerForm {...props} formComponent={formComponent} />);

    // we want to preserve props from outside, so we store them in this props, and then
    // add them all into form later
    return (props) => <InnerFormComponent ownProps={{...props}} />;
};
