// @ts-nocheck
import {Component, ReactNode, FunctionComponent} from 'react';
import {Field as ReduxFormField, Validator, Normalizer} from 'redux-form';
import memoizeOne from 'memoize-one';

import {InputLayout} from 'components';

import {FormInput} from './components';
import FormField from './FormField';
import {compose} from './normalization';
import {required as requiredField} from './validation';

const DEFAULT_INPUT_MAX_LENGTH = 255;

/**
 * This is to replace default redux form behavior, when only first failing validator is returned.
 */
const createGroupedValidation =
    (...validators) =>
    (value, values, formParams, fieldName) => {
        const errorArray = validators.map((validationFn) => validationFn(value, values, formParams, fieldName)).filter((error) => !!error);
        if (errorArray.length > 0) {
            return errorArray;
        }
        return undefined;
    };

type FieldProps = Readonly<{
    name: string;
    component?: ReactNode;
    layout?: FunctionComponent<any>;
    validate?: Validator[];
    normalize?: Normalizer | Normalizer[];
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    maxLength?: number;
    warning?: string;
    [key: string]: unknown;
}>;

export class Field extends Component<FieldProps> {
    constructor(props: FieldProps) {
        super(props);
        /**
         * For each instance of field, we will create memoized function for grouping ensuring, that for similar group of validators,
         * new grouped validator have same reference.
         * Its important to call them with destructuring, since we care about identities of validators inside of array, not about identity
         * of array, which will be new everytime.
         * This is to prevent strange behaviour of redux-form, which was failing when each render, new validators references were given.
         *
         * WARNING: currently, its working only for non-changeable validations. For ability to change validations during component lifecycle,
         * you need to implement this in componentWillUpdate (or deriveStateFromProps as for react 16)
         */
        this.groupErrorValidators = memoizeOne(createGroupedValidation);
    }

    render() {
        const {
            component = FormInput,
            layout = InputLayout,
            validate = [],
            normalize = null,
            disabled = false,
            required = false,
            maxLength = DEFAULT_INPUT_MAX_LENGTH,
            ...rest
        } = this.props;
        const passedProps = {
            component: FormField,
            layoutComponent: layout,
            inputComponent: component,
            format: null, // https://redux-form.com/7.4.2/docs/api/field.md/#-code-format-value-name-gt-formattedvalue-code-optional-
            normalize: Array.isArray(normalize) ? compose(...normalize) : normalize,
            required,
            maxLength,
            ...rest,
        };
        const validators = required ? [requiredField, ...validate] : validate;
        return (
            <ReduxFormField {...passedProps} disabled={disabled} validate={!disabled ? [this.groupErrorValidators(...validators)] : []} />
        );
    }
}
