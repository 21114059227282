export type RegisterBoxDto = Readonly<{
    name: string;
    serialNumber: string;
    street: string;
    city: string;
    zip: string;
    pins: Array<string>;
}>;

export type PinObject = Readonly<{
    id: number | null;
    boxId: number;
    primaryPin: boolean | null;
    name: string;
    value: string;
}>;

export enum BoxState {
    Registered = 'REGISTERED',
    Unregistered = 'UNREGISTERED',
}

export type BoxObject = Readonly<{
    id: number;
    name: string;
    serialNumber: string;
    street: string;
    city: string;
    zip: string;
    boxState: BoxState;
    offline: boolean;
    pins: ReadonlyArray<PinObject>; // todo: we don't want to have this here, we want to fetch the PINs per box
}>;
