import {PIN_LENGTH} from 'core/constants';

export const TO_TRANSLATE_PREFIX = 'TRANSLATE:'; // Will be used to distinguish that this error should be translated

// Regex defined in HTML 5.2 spec - https://www.w3.org/TR/html5/sec-forms.html#email-state-typeemail
const emailRegex = /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
const phoneRegex = /^\+[1-9]\d{1,14}$/;
const PASSWORD_MIN_LENGTH = 6;

const createValidator = (test: (value: string) => boolean, errorMessage: string) => (value: string) =>
    value && !test(value) ? errorMessage : undefined;

export const createMinLengthValidator = (minLength: number, errorMessage: string) =>
    createValidator((value: string) => value.length >= minLength, errorMessage);

export const required = (value?: unknown): string | undefined => (value ? undefined : `${TO_TRANSLATE_PREFIX}error.required`);

export const email = createValidator((value: string) => emailRegex.test(value.trim()), `${TO_TRANSLATE_PREFIX}error.email.notValid`);

export const phone = createValidator((value: string) => phoneRegex.test(value), `${TO_TRANSLATE_PREFIX}error.phone.notValid`);

export const createPasswordsMatcher = (passwordFieldName: string) => (value: string, allValues: Record<string, string>) =>
    value !== allValues[passwordFieldName] ? `${TO_TRANSLATE_PREFIX}error.password.matchError` : undefined;

export const passwordMinLength = createMinLengthValidator(PASSWORD_MIN_LENGTH, `${TO_TRANSLATE_PREFIX}error.password.minLengthError`);

export const createLengthValidator = (length: number, errorMessage: string = `${TO_TRANSLATE_PREFIX}error.length`) =>
    createValidator((value: string) => value.length === length, errorMessage);

export const pinLength = createLengthValidator(PIN_LENGTH, `${TO_TRANSLATE_PREFIX}error.length.pin`);
