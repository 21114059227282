import {userActionGroup} from './actions';
import {NAME} from './constants';
import reducer from './reducer';
import {isLoggedIn, getUser, getIsLoggedUserAdmin} from './selectors';
import {userSaga} from './saga';

export {Role} from './types';

export default {
    NAME,
    reducer,
    saga: userSaga,
    isLoggedIn,
    getIsLoggedUserAdmin,
    getUser,
    userActionGroup,
};
