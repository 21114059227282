import {NAME} from './constants';
import type {UserDto, Language} from '../types';

const SET_LOGGED_USER = `${NAME}/SET_LOGGED_USER`;
const REMOVE_LOGGED_USER = `${NAME}/REMOVE_LOGGED_USER`;
const SET_IS_LOGGED_USER_ADMIN = `${NAME}/SET_IS_LOGGED_USER_ADMIN`;
const UPDATE_USER_LANGUAGE = `${NAME}/UPDATE_USER_LANGUAGE`;

export const userActionGroup = {
    SET_LOGGED_USER,
    setLoggedUser: (user: UserDto) => ({
        type: SET_LOGGED_USER,
        payload: user,
    }),

    REMOVE_LOGGED_USER,
    removeLoggedUser: () => ({type: REMOVE_LOGGED_USER}),

    SET_IS_LOGGED_USER_ADMIN,
    setIsLoggedUserAdmin: (isAdmin: boolean) => ({
        type: SET_IS_LOGGED_USER_ADMIN,
        payload: isAdmin,
    }),

    UPDATE_USER_LANGUAGE,
    updateUserLanguage: (language: Language) => ({
        type: UPDATE_USER_LANGUAGE,
        payload: language,
    }),
};
