import {takeLatest, call, select} from 'redux-saga/effects';

import type {SagaWithReturnType} from '../types';
import {userActionGroup} from './actions';
import {isLoggedIn} from './selectors';
import {usersApi} from '../api';
import message from '../message';

export const userSaga: SagaWithReturnType = function* () {
    yield takeLatest(userActionGroup.UPDATE_USER_LANGUAGE, updateLanguageSaga);
};

const updateLanguageSaga: SagaWithReturnType<[ReturnType<typeof userActionGroup.updateUserLanguage>]> = function* ({payload: language}) {
    try {
        const userLoggedIn = yield select(isLoggedIn);

        if (userLoggedIn) {
            yield call(usersApi.updateUserLanguage, language);
        }
    } catch (e) {
        yield call(message.show, {translateKey: 'common.server.error', type: message.MessageType.Error});
    }
};
