import type {UserDto} from '../types';

export type CoreUserState = Readonly<{
    loggedUser: UserDto | null;
    isLoggedUserAdmin: boolean;
}>;

export enum Role {
    Admin = 'ADMIN',
    User = 'USER',
}
