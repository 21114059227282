import type {UserRole} from 'core/types';
import {fetch} from 'core/util';

import {getEndpoint} from './util';

const getAdminBoxes = () => fetch.doGet(getEndpoint('/api/admin/boxes'));

const getAdminBox = (boxId: number) => fetch.doGet(getEndpoint(`/api/admin/boxes/${boxId}`));

const getAdminUsers = () => fetch.doGet(getEndpoint('/api/admin/users'));

const updateUserRole = (userId: number, newRole: UserRole) =>
    fetch.doPut(getEndpoint(`/api/admin/users/${userId}/role`), JSON.stringify(newRole));

export const adminApi = {
    getAdminBoxes,
    getAdminBox,
    getAdminUsers,
    updateUserRole,
};
