import type {Selector} from 'react-redux';

import {app} from 'core/util';

import type {AppState} from '../types';
import {NAME} from './constants';
import type {RouteParams} from './types';

const getModel = app.createGetModel(NAME);

export const getCurrentRoute: Selector<AppState, string | null> = (state) => getModel(state).activeRoute;
export const getCurrentParams: Selector<AppState, RouteParams> = (state) => getModel(state).activeRouteParams;
export const getLoginRedirectPath: Selector<AppState, string | null> = (state) => getModel(state).loginRedirectPath;
