import type {FunctionComponent} from 'react';
import type {WrappedFieldInputProps, WrappedFieldMetaProps} from 'redux-form';

import {DatePicker} from 'components';
import type {SiteEnum} from 'core/enum';
import {format} from 'core/util';

type DateInputProps = {
    input: WrappedFieldInputProps;
    id: string;
    placeholder: string | undefined;
    disabledDate: (currentDate: Date) => boolean;
    meta: WrappedFieldMetaProps;
    site: SiteEnum;
    disabled?: boolean;
    allowClear?: boolean;
};

export const DateInput: FunctionComponent<DateInputProps> = ({input, id, placeholder, disabledDate, meta, disabled, allowClear, site}) => (
    <DatePicker
        value={input.value}
        onChange={input.onChange}
        id={id}
        placeholder={placeholder}
        disabledDate={disabledDate}
        hasError={meta.touched && meta.invalid}
        disabled={disabled}
        allowClear={allowClear}
        dateDisplayFormat={format.getDateFormatWithoutTime(site)}
    />
);
