import {call, put, select, takeEvery} from 'redux-saga/effects';

import {LOGIN} from 'app/constants';
import fetching from 'core/fetching';
import {authApi} from 'core/api';
import router from 'core/router';
import message from 'core/message';
import user from 'core/user';
import config from 'core/config';

import type {SagaWithReturnType, SagaYieldReturn} from '../types';
import {sessionActionGroup} from './actions';
import {LOGIN_LOADING, LOGOUT_LOADING} from './constants';
import {processLoginErrors, processUserLogin} from './utils';

const authSaga: SagaWithReturnType = function* () {
    yield takeEvery(sessionActionGroup.LOGIN, loginSaga);
    yield takeEvery(sessionActionGroup.LOGOUT, logoutSaga);
};

const loginSaga: SagaWithReturnType<[ReturnType<typeof sessionActionGroup.login>]> = function* ({
    payload: {email, password, recaptchaToken},
}) {
    try {
        yield put(fetching.startImmediate(LOGIN_LOADING));
        const firebaseApiKey = yield select(config.getFirebaseApiKey);

        const {idToken}: SagaYieldReturn<typeof authApi.firebaseLogin> = yield call(authApi.firebaseLogin, {
            email: email.trim(),
            password: password.trim(),
            firebaseApiKey,
        });

        yield call(processUserLogin, idToken, recaptchaToken);
    } catch (e) {
        yield call(processLoginErrors, e);
    }
};

const logoutSaga: SagaWithReturnType<[ReturnType<typeof sessionActionGroup.logout>]> = function* () {
    try {
        yield put(fetching.startImmediate(LOGOUT_LOADING));

        yield call(authApi.sessionLogout);
        yield put(user.userActionGroup.removeLoggedUser());
        yield put(user.userActionGroup.setIsLoggedUserAdmin(false));

        yield put(router.navigate(LOGIN, true));

        yield call(message.show, {translateKey: 'logout.success'});
    } catch (e) {
        yield call(message.show, {translateKey: 'logout.error', type: message.MessageType.Error});
    } finally {
        yield put(fetching.stop(LOGOUT_LOADING));
    }
};

export default authSaga;
