import type {FunctionComponent} from 'react';
import type {WrappedFieldInputProps} from 'redux-form';

import {Input} from 'components';

type FormInputType = 'text' | 'number' | 'password';

type FormInputProps = Readonly<{
    input: WrappedFieldInputProps;
    placeholder: string;
    tabIndex: number;
    type: FormInputType;
    disabled: boolean;
    id: string;
    pattern: string;
}>;

export const FormInput: FunctionComponent<FormInputProps> = ({input, placeholder, tabIndex, type, disabled, id, pattern, ...rest}) => (
    <Input
        {...rest}
        value={input.value || ''}
        placeholder={placeholder}
        onChange={input.onChange}
        pattern={pattern}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        tabIndex={tabIndex}
        disabled={disabled}
        type={type}
        id={id}
    />
);
