import type {ComponentType} from 'react';

import type {Route} from './types';
import type {TypedObject} from '../types';

// TODO create some better code for globally stored map?
let memory: Record<string, Route | undefined> = {};

/**
 * Registers routes so they can be used by router.
 * @param routes Routes to register. Object where keys are names of routes and values are routes themselves. If route with same name is already registered, it is overridden by new value.
 */
export const registerRoutes = (routes: TypedObject<Route>) => {
    memory = {
        ...memory,
        ...routes,
    };
    return routes;
};

/**
 * Assigns Container component to route with specified routeName.
 * @param routeName Name of route.
 * @param Container Container component which is displayed when route is active.
 * @throws Throws error when route with specified routeName is not registered. Routes can be registered by {@link registerRoutes}.
 */
export const setComponent = (routeName: string, Container: ComponentType) => {
    const route = memory[routeName];
    if (route) {
        memory[routeName] = {
            ...route,
            Container,
        };
    } else {
        throw new Error(`Cannot set component for non existing route ${routeName}. Use registerRoutes to register route first.`);
    }
};

/**
 * Gets all registered routes.
 */
export const getRegisteredRoutes = (): Readonly<typeof memory> => memory;
