import type {GenericObject} from 'core/types';

import {EMPTY_OBJECT, EMPTY_ARRAY} from '../constants';

export type ModelConverter<T> = Readonly<{
    name: string;
    fromServer: (json: GenericObject | undefined) => T;
    fromServerList: (json: ReadonlyArray<GenericObject> | undefined) => ReadonlyArray<T>;
    toString: () => string;
}>;

export type ConvertFn<T> = (json: GenericObject) => T;

/**
 * Creates model class with fromServer and fromServerList methods.
 * @param {string} name Model class name.
 * @param {Function} fromJson Transformation function from json to object prototype. Must return valid object even for empty JSON.
 *
 * @example
 *  export default createModel('Agent', (json) => ({
        idAgentContractAgent: json.IdObject_AgentContract_Agent,
        legalEntity: LegalEntity.fromServer(json.Agent),
        branch: Branch.fromServer(json.Branch),
        socialNetwork: SocialNetwork.fromServer(json.SocialNetwork),
        productList: List(json.Products),
    });
 */
export default <T>(name: string, fromJson: ConvertFn<T>): ModelConverter<T> => {
    const fromServer = (json = EMPTY_OBJECT) => fromJson(json);
    return {
        name,
        fromServer,
        fromServerList: (json = EMPTY_ARRAY) => json.map(fromServer),
        toString: () => `${name} entity Converter object`,
    };
};
