import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import cs from '../../translations/cs.yaml';
import de from '../../translations/de.yaml';
import en from '../../translations/en.yaml';
import {Language} from '../types';

const resources = {
    [Language.en]: {translation: en},
    [Language.cs]: {translation: cs},
    [Language.de]: {translation: de},
};

i18next.use(initReactI18next).init({
    // debug: true,
    resources,
    // This value has to be set, but it is overridden by the value from the saga(load config)
    lng: Language.cs,
    keySeparator: false,
    parseMissingKeyHandler: (key) => `(${key})`,
});

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
i18next.on('languageChanged', (lng) => {
    // set locale to libraries (moment) which needs them
});

export default i18next;
