import {fn} from 'core/util';

import type {RouterWrapper} from './types';

export const routerWrapper = ({
    initPageState = () => [],
    onPageEnter = fn.noop,
    getDataForPage = () => [],
    clearDataForPage = () => [],
}: Partial<RouterWrapper>): RouterWrapper => ({
    initPageState,
    onPageEnter,
    getDataForPage,
    clearDataForPage,
});
