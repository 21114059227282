import invariant from 'invariant';
import type {Reducer, AnyAction} from 'redux';

/**
 * Creates "data" reducer which supports to set value by set action or clear value to initial state by clear action.
 * Created reducer does not check type of payload of set data action. It is responsibility of user to ensure action payload and reducer type match.
 *
 * @param setDataActionType Type of action which is used to set data. Data must be specified in payload filed of action, otherwise error is thrown.
 * @param clearActionType Type of action which is used to reset state to initial value.
 * @param initialState Initial state of reducer. Default value is null.
 * @template S Type of state for created reducer. Possibility to set null value is added automatically.
 */
export const createDataReducer =
    <S>(setDataActionType: string, clearActionType: string, initialState: S | null = null): Reducer<S | null> =>
    (state = initialState, action: AnyAction) => {
        if (action.type === clearActionType) {
            return initialState;
        } else if (action.type === setDataActionType) {
            invariant(!!action.payload, "Action set to 'createDataReducer' must have payload field.");
            return action.payload || initialState;
        }
        return state;
    };
