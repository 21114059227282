import {Locale} from 'core/enum';
import {model, fn} from 'core/util';

export const Config = model.createModel('Config', (json) => {
    if (fn.isObjectKey(Locale, json.locale)) {
        return {
            locale: Locale[json.locale],
        };
    } else {
        throw new Error(`locale has unsupported value ${json.locale} `);
    }
});
