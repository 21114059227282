export enum Palette {
    yellow10 = '#fffee6',
    yellow20 = '#fff9a3',
    yellow30 = '#fff27a',
    brandColor40 = 'var(--brand-color-40)',
    yellow50 = '#ffdb29',
    brandColor60 = 'var(--brand-color-60)',
    yellow70 = '#d9a600',
    yellow80 = '#b38300',
    yellow90 = '#8c6200',
    yellowX0 = '#664400',
    accentColor10 = 'var(--accent-color-10)',
    accentColor20 = 'var(--accent-color-20)',
    blue30 = '#63a9cf',
    accentColor40 = 'var(--accent-color-40)',
    blue50 = '#1d76b5',
    accentColor60 = 'var(--accent-color-60)',
    blue70 = '#004382',
    blue80 = '#002c5c',
    blue90 = '#001836',
    blueX0 = '#00060f',
    gray10 = '#f2f2f2',
    gray20 = '#e6e6e6',
    gray30 = '#dadada',
    gray40 = '#b3b3b3',
    gray50 = '#8c8c8c',
    gray60 = '#666666',
    gray70 = '#4f4e4a',
    gray80 = '#363532',
    gray90 = '#292926',
    grayX0 = '#1d1d1b',
    white = '#ffffff',
    black = '#000000',
    red = '#ff4d4f',
    green = '#52c41a',
    orange = '#ff8800',
    interactionShadow = 'var(--interaction-shadow)',
    highlightColor = 'var(--highlight-color)',
}

export enum FontSize {
    '_12' = '0.75rem',
    '_14' = '0.875rem',
    '_16' = '1rem',
    '_18' = '1.125rem',
    '_20' = '1.25rem',
    '_22' = '1.375rem',
    '_24' = '1.5rem',
    '_26' = '1.625rem',
    '_30' = '1.875rem',
    '_32' = '2rem',
    '_40' = '2.5rem',
}
